@font-face {
  font-family: brFont;
  src: url(./fonts/coolvetica.otf);
}

@font-face {
  font-family: montserrat;
  src: url(./fonts/Montserrat-Light.ttf);
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
  background-color: #f8f9fa;
  height: 100%;
}

.contenedor__exterior{
  height: 100%;
}

a {
  text-decoration: none;
  color: white;
}

.nav {
  border-bottom: #fff solid 1px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  background-color: rgba(39,62,84,0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.botonRegistro, .botonPanel, .linkNav {
  transition: all 0.5s;
  display: inline-block;
  margin-top: 4vw;
}

.botonRegistro:hover, .botonPanel:hover, .linkNav:hover {
  transform: scale(1.1);
}

.backgroundImage {
  background: url(./images/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.colorOverlay {
  background: rgba(39,62,84,0.6);
  overflow: hidden;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainElements {
  display: grid;
  gap: 10px;
  grid-template-areas:
  "logo logo"
  "titulo titulo" 
  "bajada bajada"
  "botonesPrincipales botonesPrincipales"
  "badge badge"
  ;
  align-items: center;
  justify-items: center;
}

.titulo {
 font-size: 3rem;
 color: white;
 font-family: brFont;
 letter-spacing: 4px;
 grid-area: titulo;
}

.logoBr {
  width: 20vw;
  grid-area: logo;
}

.bajada {
  grid-area: bajada;
  color: white;
  text-align: center;
  font-size: 1.25rem;
  padding: 0.5rem;
}

.botonesPrincipales {
  display: grid;
  gap: 10px;
  grid-template-areas:
  "botonPanel"
  "linkSticker"
  ;
  align-items: center;
  justify-items: center;
  grid-area: botonesPrincipales;
}

.botonRegistroPrincipal {
  grid-area: botonRegistra;
  background-color: #2a3e51;
  border-color: white;
}

.botonPanelPrincipal {
  grid-area: botonPanel;
  background-color: #2a3e51;
  border-color: white;
}

.linkSticker {
  grid-area: linkSticker;
  font-size: 1rem;
}

.gplayBadge {
  width: 30vw;
}

.badge {
  grid-area: badge;
  font-size: 3vw;
  font-weight: lighter;
}

.contenedor {
  background-color: white;
  display: grid;
  gap: 10px;
  grid-template-areas:
  "titularServicio"
  "imagen"
  "texto"
  "thief"
  "paramedic"
  ;
  align-items: center;
  justify-items: center;
}

.titularServicio {
  grid-area: titularServicio;
  color: #2a3e51;
  text-align: center;
}

.texto {
  grid-area: texto;
  text-align: center;
  color: #2a3e51;
  font-size: 1.1rem;
}

.imagen {
  grid-area: imagen;
  width: 80vw;
}

.thief {
  grid-area: thief;
}

.paramedic {
  grid-area: paramedic;
}

.serviceIcon {
  width: 50vw;
}

.serviceIcons {
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.iconText {
  text-align: center;
  color: #2a3e51;
  font-size: 1.1rem;
}

.tituloIcon {
  color: #2a3e51;
}

.aliados {
  margin-top: 8vw;
  margin-bottom: 8vw;
}

.container__aliados {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.titularAliados {
  color: #2a3e51;
  text-align: center;
}

.logoAliados {
  width: 15vw;
  margin: 1vw;
}

.logoWrap {
  position: relative;
  text-align: center;
}

.logoText {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2a3e51;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity .75s, visibility .75s;
  font-weight: bolder;
  padding-top: 2.25vw;
  padding-left: 1vw;
  padding-right: 0.5vw;
}

.logoWrap:hover .logoText{
  visibility: visible;
  opacity: .85;
}

.logoText a:hover {
  color: white;
}

.titularSticker {
  grid-area: titularSticker;
  color: #2a3e51;
  text-align: center;
}

.contenedor__sticker {
  background-color: white;
  display: grid;
  gap: 10px;
  grid-template-areas:
  "titularSticker"
  "parrafoSticker"
  "imagenSticker"
  "textoSticker"
  ;
  align-items: center;
  justify-items: center;
}

.imagenSticker {
  grid-area: imagenSticker;
  width: 80vw;
}

.textoSticker {
  grid-area: textoSticker;
  font-size: 4vw;
}

.parrafoSticker {
  grid-area: parrafoSticker;
  text-align: center;
  color: #2a3e51;
  font-size: 1.1rem;
}

.logoMl {
  width: 30vw;
}

.marcaLider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.titularFaq {
  color: #2a3e51;
  text-align: center;
}

.contenedorFaq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8vw;
}

.contenedorFaq a {
  color: #2a3e51;
  font-weight: bolder;
}

.containerFooter {
  background-color: #2a3e51;
  color: white;
  font-size: 0.8rem;
  position:fixed;
  left:0;
  bottom:0;
  right:0;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.spacer {
  padding-top: 20vw;
}

.registrationForm {
  margin: 0;
  position: absolute;
  top: 60%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 25px;
  border-color: #2a3e51;
}

.titularForm {
  color: #2a3e51;
}

.registrationInputs {
  font-size: 1.15vw;
}

.registrationInput {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid darkcyan;
}

.registrationInput:focus {
  outline: none;
}

.registrationInput::placeholder {
  color: lightgray;
}


.formStepper__bullets li {
  display: inline-block;
  list-style-type: none;
  margin: 2vw;
  font-size: 1.75vw;
  color: white;
  border-radius: 50%;
  width: 2.5vw;
  height: 2.5vw;
  position: relative;
}

.formStepper__bullet, .formStepper__bullet--last {
  background-color: lightgray;
}

.formStepper__bullet::before {
  background-color: lightgray;
  width: 2px;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4.4vw;
  transform: rotate(90deg);
}

.formStepper__bullet--last::before {
  content: none;
}

.active {
  background-color: darkcyan;
}

 .botonSiguiente {
   background-color: darkcyan;
   display: inline;
   font-size: 1vw;
 }

 .botonSiguiente:hover {
   background-color: rgb(2, 105, 105);
 }

 .inlineInput {
   display: block;
 }

 #inputsNewAccount {
  display: grid;
  grid-template-areas:
  "inputDividerData inputDividerData"
  "inputBlood inputBlood"
  "inputDNI inputDNI"
  "inputDividerContact inputDividerContact"
  "inputContactName inputContactName"
  "inputContactNumber inputContactNumber"
  "terminos terminos"
  "termsButton termsButton"
  "captcha captcha"
  "sendNewAccount sendNewAccount"
  ;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;

 }

 #terminos {
   grid-area: terminos;
   font-size: 1vw;
 }

 #termsButton {
   grid-area: termsButton;
 }

 #inputName {
   grid-area: inputName;
 }

 #sendNewAccount {
  grid-area: sendNewAccount;
}

 #inputLastName {
   grid-area: inputLastName;
 }

 #inputEmail {
   grid-area: inputEmail;
 }

 #inputDNI {
  grid-area: inputDNI;
}

#inputBlood {
  grid-area: inputBlood;
}

#inputDividerContact {
  grid-area: inputDividerContact;
}

#inputDividerBike {
  grid-area: inputDividerBike;
}

#inputDividerData {
  grid-area: inputDividerData;
}

#inputContactName {
  grid-area: inputContactName;
}

#inputContactNumber {
  grid-area: inputContactNumber;
}

#inputContactBrand {
  grid-area: inputContactBrand;
}

#inputContactModel {
  grid-area: inputContactModel;
}

#inputContactSerie {
  grid-area: inputContactSerie;
}

#inputContactFoto {
  grid-area: inputContactFoto;
}

#inputContactDescripcion {
  grid-area: inputContactDescripcion;
  height: 5vw;
  resize: none;
}

.listaTerminos li {
  margin-bottom: 1vw;
}

.fullScreen {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
}

.fullScreenB {
  height: 50%;
  position: absolute;
  left: 0;
  width: 100%;
}

.marginSpinner {
  margin-top: 45vh;
}

.googleButton {
  width: 40vw;
}

.googleButton:hover {
  cursor: pointer;
}

.terms {
  text-decoration: none;
  color: white;
}

.terms:hover{
  color: white;
}

.formBox{
  width: 75vw;
  height: auto;
  margin-top: 10vh;
}

.formBox--bottom{
  margin-top: 2vh;
}

.loader {
  border: 10px solid #b4b4b4;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: relative;
  left: 10%;
  top: 50%;
  -webkit-transform: translate(-10%, -50%);
  transform: translate(-10%, -50%);
  z-index: 999; 
}

.modalLoading {
  width: 10vw;
}

.panelCard {
  width: 15rem;
  transition: 0.8s;
}

.panelCard:hover {
  background-color: rgba(248, 246, 246, 0.897);
  cursor: pointer;
}

.plusCard {
  font-size: 6rem;
  font-weight: lighter;
  width: 8rem;
  height: 8rem;
  font-family: montserrat;
}

/* #inputsNewBike {
  display: grid;
  gap: 25px;
  grid-template-areas:
  "inputContactBrand inputContactSerie"
  "inputContactModel inputContactModel"
  "bikeFoto inputContactFoto"
  "inputContactDescripcion inputContactDescripcion"
  "captcha sendNewAccount"
  ;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;

 } */

 #captcha{
  grid-area: captcha;
 }

 .bikeFoto {
   grid-area: bikeFoto;
 }

 input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  transition: 0.5s;
}

.custom-file-upload:hover {
  background-color: rgba(248, 246, 246, 0.897);
}

#inputFoto {
  max-width: 300px;
  max-height: 300px;
}

.inputContactFoto__sizeAlert {
  font-size: 0.85rem;
  width: 40vw;

}

.bikeCard__title {
  font-size: 1rem;
  color: gray
}



.bikeDetail__type {
  font-weight: bold;
  font-size: 0.95rem;
  color: gray;
}

.bikeDetail__wraperQR {
  position: relative;
  width: 125px;
  height: 125px;
}

.bikeDetail__wraperQR__Base, .bikeDetail__wraperQR__QR {
  position: absolute;
  top: 20px;
  right: 25px;
}

.bikeDetail__wraperQR__QR {
  z-index: 99;
}

.bikeDetail__wrapperQR__dIcon {
  color:rgb(2, 105, 105) ;
}

.bikeDetail__wrapperQR__dIcon:hover {
  cursor: pointer;
}

.bikeDetail__modify {
  transition: ease 0.5s;
}

.bikeDetail__modify:hover {
  background-color: #2a3e51;
  color: #bbbbbb;
  cursor: pointer;
}

.bikeDetail__modify:hover:disabled {
  background: #fafafa;
  color: lightgray;
}

.text-divider{
  font-size: 1.25rem;
}

.marginBox {
  margin-top: 15vw;
  margin-bottom: 15vw;
}

.marginBoxPanel {
  margin-top: 0;
  margin-bottom: 15vw;
}

.marginBoxBikeDetail {
  margin-top: 15vw;
  margin-bottom: 15vw;
}














/*Tablets*/

@media screen and (min-width:768px) { 

  .botonesPrincipales {
    grid-template-areas:
    "botonPanel"
    "linkSticker"
    ;
  }

  .gplayBadge {
    width: 20vw;
  }
  
  .badge {
    font-size: 2vw;
  }

  .contenedor {
    grid-template-areas:
    "titularServicio titularServicio"
    "imagen imagen"
    "texto texto"
    "thief paramedic"
    ;
    align-items: flex-start;
  }

  .serviceIcons {
    width: 30vw;
  }

  .textoSticker {
    font-size: 2.5vw;
  }

  .logoMl {
    width: 20vw;
  }

  .containerFooter {
    font-size: 1rem;
  }

  .spacer {
    padding-top: 10vw;
  }

  .googleButton {
    width: 30vw;
  }

  .marginBox {
    margin-top: 9vw;
    margin-bottom: 9vw;
  }

  .marginBoxBikeDetail {
    margin-top: 10vw;
    margin-bottom: 12vw;
  }

  .inputContactFoto__sizeAlert {
    width: 20vw;
  
  }

}

/*Nav Brakpoint*/

@media screen and (min-width:992px) { 

  .botonRegistro, .botonPanel, .linkNav {
    margin-top: 0vw;
  }

  .googleButton {
    width: 25vw;
  }

  .marginBox {
    margin-top: 7vw;
    margin-bottom: 7vw;
  }

  .marginBoxPanel {
    margin-bottom: 10vw;
  }

  .marginBoxBikeDetail {
    margin-bottom: 10vw;
  }

  .inputContactFoto__sizeAlert {
    width: 10vw;
  
  }

}


/*Desktop chica*/

@media screen and (min-width:1200px) { 

  .mainElements {
    grid-template-areas:
    "logo titulo"
    "bajada bajada"
    "botonesPrincipales botonesPrincipales"
    "badge badge"
    ;
  }

  .logoBr {
    width: 12vw;
    margin-right: 2vw;
  }

  .titulo {
    font-size: 7rem;
   }

   .bajada {
    font-size: 1.75rem;
  }

   .gplayBadge {
    width: 13vw;
  }
  
  .badge {
    font-size: 1.5vw;
  }

  .contenedor {
    grid-template-areas:
    "titularServicio titularServicio"
    "texto imagen"
    "thief paramedic"
    ;
    align-items: center;
  }

  .imagen {
    width: 45vw;
  }

  .serviceIcons {
    width: 20vw;
  }

  .logoAliados {
    width: 8vw;
  }

  .contenedor__sticker {
    grid-template-areas:
    "titularSticker titularSticker"
    "parrafoSticker parrafoSticker"
    "imagenSticker textoSticker"
    ;
  }

  .imagenSticker {
    width: 45vw;
  }

  .textoSticker {
    font-size: 1.5vw;
  }

  .logoMl {
    width: 12vw;
  }

  .spacer {
    padding-top: 5vw;
  }

  .googleButton {
    width: 15vw;
  }

  .marginBox {
    margin-top: 6vw;
    margin-bottom: 6vw;
  }

  .marginBoxPanel {
    margin-bottom: 5vw;
  }

  .marginBoxBikeDetail {
    margin-top: 7vw;
    margin-bottom: 8vw;
  }

}


@media screen and (min-width:1400px) { 

  .marginBoxPanel {
    margin-top: 0vw;
    margin-bottom: 8vw;
  }

  .marginBoxPanel {
    margin-bottom: 2vw;
  }
  
}

/*Desktop*/

@media screen and (min-width:1920px) { 

  .logoBr {
    width: 8vw;
  }

  .gplayBadge {
    width: 8vw;
  }
  
  .badge {
    font-size: 1vw;
  }

  .linkSticker {
    font-size: 1.25rem;
  }

  .imagen {
    width: 35vw;
  }

  .texto {
    font-size: 1.25rem;
  }

  .serviceIcons {
    width: 15vw;
  }

  .logoAliados {
    width: 5vw;
  }

  .imagenSticker {
    width: 35vw;
  }

  .textoSticker {
    font-size: 1.1vw;
  }

  .logoMl {
    width: 8vw;
  }

  .spacer {
    padding-top: 4vw;
  }

  .marginBoxPanel {
    margin-top: 5vw;
    margin-bottom: 0;
  }

  .marginBoxBikeDetail {
    margin-top: 5vw;
    margin-bottom: 5vw;
  }

  .inputContactFoto__sizeAlert {
    width: 8vw;
  
  }

}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}